/* Gallery.css */
.gallery {
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 60px;
    padding: 20px;
  }
  
  .gallery-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }

@media (max-width:720px) {
  .gallery-items{
  grid-template-columns: repeat(1, 1fr);
  }
}
  
  .gallery-item {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%;
  }
  
  .description {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .gallery-item:hover {
    transform: scale(1.1);
  }
  
  .gallery-item:hover .description {
    opacity: 1;
    visibility: visible;
  }
  