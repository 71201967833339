.contact-form-container {
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 80px;
    padding: 20px;
    background-color: #e0e5ec;
    border-radius: 10px;
    box-shadow: 16px 16px 16px gray;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 5px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 10px;
  }
  
  input,
  textarea {
    margin: auto;
    width: 50%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
    border-radius: 4px;
    cursor: pointer;
    width: fit-content;
  }
  
  button:hover {
    background-color: #45a049;
  }

  @media (max-width:420px){
    .contact-form-container{
      width: 91%;
    }  
}