form label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #333;
}

form input,
form select {
    transition: all 0.3s ease-in-out;
    background-color: #e0e0e0;
    border-radius: 10px;
    box-shadow: 7px 7px 15px #bebebe, -7px -7px 15px #ffffff;
    border: none;
    padding: 10px;
    font-size: 14px;
    width: 100%;
    margin-bottom: 15px;
}

form input:focus,
form select:focus {
    outline: none;
    box-shadow: inset 3px 3px 5px #bebebe, inset -3px -3px 5px #ffffff;
}

form div {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 10px;
    color: black;
    font-size: large;
}

.section1, .section2, .section3, .section4 {
    border-radius: 20px;
    box-shadow: 5px 3px 8px 8px rgb(167, 167, 168);
    border: solid rgb(167, 167, 168);
    padding-top: 30px;
}

select{
    margin:auto;
}

button{
    color: black;
    border: solid rgb(255, 255, 255);
}