
.testimonial-container {
    display: flex;
    align-items: center;
}
.testimonial{
    /* display: flex;
    grid-template-columns: 1fr 1fr;
    box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius:20%;
    width:80%;
    background-color: antiquewhite;
    margin: 15px;
    height: 200px;
    padding: 10px;
    left: 5%;
    position: relative; */
    padding: 1rem;
    border-radius: 4rem;
    background-color: antiquewhite;
    position: relative;
    z-index: -1;
    left: -45px;
}

.testimonial-image{
    /* justify-content: flex-end;
    position: absolute;
    border-radius: 50%;
    border: 5px solid;
    border-color: white;
    left: -20%;
    top: 10%;
    bottom: 10%;
    margin: 5px;
    height: 70%;
    width: 25%;
    white-space: nowrap; 
    text-overflow: ellipsis; */
    height: 10rem;
    width: 10rem;
    border-radius: 100%;
    z-index: 0;
    border: 5px solid white;
}

.testimonial .button {
    position: absolute;
    align-items: center;
    padding: 5%;
    bottom: 5%;
    height: 10px;
}

.testimonial .testimonial-text{
    /* position: absolute;
    align-items: center;
    padding: 5%;
    top: 50%;
    transform: translateY(-50%);
    font-size: x-large;
    font-family:cursive; */
    
    /*position: absolute; */
    margin: 1rem;
}

.testimonial .testimonial-author{
    /* position: absolute;
    left : 50%;
    bottom : 3%;
    transform: translateX(-50%);
    text-align: end;
    color: brown;
    font-size: large;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */

    left: 50%;
    bottom: 3%;
    text-align: center;
    color: brown;
    font-size: large;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

