/* .container {
    padding: 20px;
  } */
  
  .service-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .service {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .service img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  h1{
    margin-bottom: 20px;
  }
  .service h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .service p {
    font-size: 16px;
    color: #777777;
  }
  